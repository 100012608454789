<template>
  <v-container fluid ma-0 pa-0 fill-width>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div class="body-container">
      <v-container v-if="packageInfo && !loading" class="mb-16">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" class="d-flex row align-end">
              <Button
                text="Volver"
                pill
                outlined
                :height="43"
                prepend-icon="fa-caret-left"
                class="mt-10 mb-10"
                aria-label="Volver a lista de productos"
                url="ProviderProducts"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h1 class="text-h1">
                {{ packageInfo.name }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-tabs
                color="secondary"
                background-color="transparent"
                slider-size="5"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  class="btn-background-transparent text-capitalize font-weight-bold black--text text-h3 px-3 px-sm-6"
                  :ripple="false"
                  style="pointer-events: none; cursor: default"
                >
                  Información
                </v-tab>
                <v-tabs-items>
                  <v-divider color="secondary"></v-divider>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
          <!-- INFORMATION CONTAINER -->
          <div>
            <!-- PACKAGE PICTURES -->
            <v-row class="mb-6">
              <v-col cols="12" lg="9">
                <div class="mt-6 mb-3 text-h2 font-weight-bold">Fotos</div>
                <div class="imgsPreview">
                  <div
                    v-for="(img, i) in packageInfo.packageImages"
                    :key="i"
                    class="imageHolder"
                  >
                    <v-img :src="img.url" aspect-ratio="1">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-1"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- GENERAL INFORMATION -->
            <v-row class="mb-6">
              <v-col cols="12">
                <p class="text-h2 mb-4">Información general</p>
                <div class="text-h4">
                  <div class="mb-4">
                    <span>Nombre del paquete: </span>
                    <span>{{ packageInfo.name }}</span>
                  </div>
                  <div class="mb-4">
                    <span>Categoría: </span>
                    <span>{{ packageInfo.type }}</span>
                  </div>
                  <div class="mb-4">
                    <span>Dirección: </span>
                    <span>
                      {{ packageInfo.address }}, {{ packageInfo.city }},
                      {{ packageInfo.state }}
                    </span>
                  </div>
                  <!-- SCHEDULES -->
                  <div class="mb-4">
                    <span>Horarios: </span>
                    <span>
                      {{ schedules.join(", ") }}
                    </span>
                  </div>
                  <!-- ROOM -->
                  <div class="mb-4">
                    <span>Capacidad: </span>
                    <span v-if="packageInfo.adultsQuantity > 0">
                      {{ packageInfo.adultsQuantity }}
                      {{
                        packageInfo.adultsQuantity === 1 ? "adulto" : "adultos"
                      }}
                    </span>
                    <span v-if="packageInfo.kidsQuantity > 0">
                      {{ packageInfo.adultsQuantity > 0 ? "y" : "" }}
                      {{ packageInfo.kidsQuantity }}
                      {{
                        packageInfo.kidsQuantity === 1 ? "niño" : "niños"
                      }}</span
                    >
                  </div>
                  <!-- EXTRAS -->
                  <div class="d-flex flex-wrap">
                    <div class="mb-4 mr-8">
                      <span>Personas extra: </span>
                      <span
                        >{{ packageInfo.extraCapacity > 0 ? "Sí" : "No" }}
                      </span>
                    </div>
                    <div
                      v-if="packageInfo.extraCapacity > 0"
                      class="d-flex flex-wrap"
                    >
                      <div class="mr-8 mb-4">
                        <span>Cantidad: </span>
                        <span>{{ packageInfo.extraCapacity }} </span>
                      </div>
                      <div class="mb-4">
                        <span>Costo por persona: </span>
                        <span
                          >{{
                            numberToCurrency(packageInfo.adultExtraPrice)
                          }}
                          MXN</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- PRICES -->
                  <div class="mb-4">
                    <div
                      v-for="(price, index) in prices"
                      :key="index"
                      class="mb-4 mr-8"
                    >
                      <span
                        >Costo{{ prices.length > 1 ? " " + (index + 1) : "" }}:
                      </span>
                      <span class="mr-8"
                        >{{ numberToCurrency(price.price) }} MXN</span
                      >
                      <span>Días: {{ price.daysOfWeek.join(", ") }} </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- ITEMS INCLUDED IN THE PACKAGE -->
            <p class="text-h4 font-weight-bold">
              Elementos que incluye el paquete
            </p>
            <v-row class="mb-6">
              <v-col cols="6" sm="6" md="4">
                <div class="d-flex justify-space-between">
                  <p class="font-weight-bold">Elemento</p>
                </div>
                <div
                  v-for="item in packageInfo.packageItems"
                  :key="item.id"
                  class="d-flex justify-space-between"
                >
                  <span class="mb-0">{{ item.name }}</span>
                </div>
                <p class="mb-0">Platillos adultos</p>
                <p v-if="packageInfo.kidsQuantity > 0" class="mb-0">
                  Platillos niños
                </p>
              </v-col>
              <v-col cols="6" md="4">
                <div class="d-flex justify-space-between">
                  <p class="font-weight-bold">Cantidad</p>
                </div>
                <div
                  v-for="item in packageInfo.packageItems"
                  :key="item.id"
                  class="d-flex justify-space-between"
                >
                  <span class="mb-0">{{ item.quantity }}</span>
                </div>
                <p class="mb-0">{{ packageInfo.adultsQuantity }}</p>
                <p v-if="packageInfo.kidsQuantity > 0" class="mb-0">
                  {{ packageInfo.kidsQuantity }}
                </p>
              </v-col>
            </v-row>
            <!-- ADDITIONAL ELEMENTS TO THE PACKAGE -->
            <div v-if="packageInfo.packageSupplementaryItems.length > 0">
              <p class="text-h4 font-weight-bold">
                Elementos adicionales al paquete
              </p>
              <v-row class="mb-6">
                <v-col cols="4" sm="6" md="4">
                  <div class="d-flex justify-space-between">
                    <p class="font-weight-bold">Elemento</p>
                  </div>
                  <div
                    v-for="item in packageInfo.packageSupplementaryItems"
                    :key="item.id"
                    class="d-flex justify-space-between"
                  >
                    <span class="mb-0">{{ item.name }}</span>
                  </div>
                </v-col>
                <v-col cols="4" sm="6" md="4">
                  <div class="d-flex justify-space-between">
                    <p class="font-weight-bold">Variación</p>
                  </div>
                  <div
                    v-for="item in packageInfo.packageSupplementaryItems"
                    :key="item.id"
                    class="d-flex justify-space-between"
                  >
                    <span class="mb-0">{{ item.unit }}</span>
                  </div>
                </v-col>
                <v-col cols="4" sm="6" md="4">
                  <div class="d-flex justify-space-between">
                    <p class="font-weight-bold">Costo</p>
                  </div>
                  <div
                    v-for="item in packageInfo.packageSupplementaryItems"
                    :key="item.id"
                    class="d-flex justify-space-between"
                  >
                    <span class="mb-0"
                      >{{ numberToCurrency(item.price) }} MXN</span
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
            <p v-else class="mb-8 text-h4 font-weight-bold">
              Sin elementos adicionales
            </p>
            <!-- CORKAGE & OVERTIME -->
            <v-row class="mb-6">
              <v-col cols="6" md="4">
                <div>
                  <span class="font-weight-bold">Descorche: </span>
                  <span>
                    {{ packageInfo.corkage ? "Permitido" : "No permitido" }}
                  </span>
                </div>
                <div v-if="packageInfo.corkagePrice">
                  <span class="font-weight-bold">Costo: </span>
                  <span>
                    {{ numberToCurrency(packageInfo.corkagePrice) }} MXN
                  </span>
                </div>
              </v-col>
              <v-col cols="6" md="4">
                <div>
                  <span class="font-weight-bold">Hora extra: </span>
                  <span>
                    {{ packageInfo.extraHour ? "Permitida" : "No permitida" }}
                  </span>
                </div>
                <div v-if="packageInfo.extraHour">
                  <span class="font-weight-bold">Costo: </span>
                  <span>
                    {{ numberToCurrency(packageInfo.extraHourPrice) }} MXN
                  </span>
                </div>
              </v-col>
            </v-row>
            <!-- AVAILABLE DISHES -->
            <p class="text-h4 font-weight-bold">Platillos disponibles</p>
            <v-row class="mb-6">
              <v-col cols="6" md="4">
                <div class="d-flex justify-space-between">
                  <p class="font-weight-bold">Adultos</p>
                </div>
                <div
                  v-for="dish in filteredDishesForAdults"
                  :key="dish.id"
                  class="d-flex justify-space-between"
                >
                  <span class="mb-0">{{ dish.name }}</span>
                </div>
              </v-col>
              <v-col cols="6" md="4">
                <p v-if="packageInfo.kidsQuantity > 0" class="font-weight-bold">
                  Niños
                </p>
                <div
                  v-for="dish in filteredDishesForKids"
                  :key="dish.id"
                  class="d-flex justify-space-between"
                >
                  <span class="mb-0">{{ dish.name }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- PAYMENT PLAN -->
            <div class="mb-6">
              <p class="text-h4 font-weight-bold">Plan de pagos</p>
              <div>
                <span class="font-weight-bold">Plan de pagos: </span>
                <span>
                  {{ numberToCurrency(packageInfo.paymentAdvance) }} MXN de
                  anticipo
                </span>
              </div>
              <div>
                <span class="font-weight-bold">Política de liquidación: </span>
                <span>
                  {{
                    packageInfo.paymentDeadline === 0
                      ? "Día del evento"
                      : packageInfo.paymentDeadline + " días antes del evento"
                  }}
                </span>
              </div>
              <div>
                <span class="font-weight-bold">Política de cancelación:</span>
                <span>
                  {{
                    packageInfo.cancellationDeadline === 0
                      ? "el día del evento"
                      : packageInfo.cancellationDeadline +
                        (packageInfo.cancellationDeadline === 1
                          ? " día previo al evento"
                          : " días previos al evento")
                  }}
                  -
                  {{
                    packageInfo.reimbursement === 0
                      ? "No ofrece reembolso"
                      : "Reembolso del " + packageInfo.reimbursement + "%"
                  }}</span
                >
              </div>
            </div>
            <!-- PACKAGE DESCRIPTION -->
            <v-row>
              <v-col cols="12" md="6">
                <p class="text-h4 font-weight-bold">Descripción</p>
                <div class="text-pre-line">
                  {{ packageInfo.description }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <Footer class="mt-10" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Footer from "@/components/Shared/Footer.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import Button from "@/components/Shared/Button.vue";
import { GET_PACKAGE } from "@/graphql/queries.js";
import { useQuery } from "@/graphql/index.js";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "PackagesDetails",
  components: {
    Navbar,
    Footer,
    InlineLoading,
    Button,
  },
  data() {
    return {
      packageInfo: null,
      loading: true,
      schedules: [],
      prices: [],
    };
  },
  computed: {
    filteredDishesForAdults() {
      return this.packageInfo.packageDishes.filter((dish) => dish.isForAdult);
    },
    filteredDishesForKids() {
      return this.packageInfo.packageDishes.filter((dish) => !dish.isForAdult);
    },
  },
  created() {
    this.fetchSinglePackageInfo();
  },
  methods: {
    async fetchSinglePackageInfo() {
      this.loading = true;
      const packageId = parseInt(this.$route.params.id);
      const { data, errors } = await useQuery(GET_PACKAGE, {
        packageId: packageId,
        isProvider: true,
      });
      if (data) {
        this.packageInfo = data.package;
        this.formatDays(this.packageInfo.packagePrices);
        this.schedules = this.formatSchedules(this.packageInfo.packageSchedule);
        this.formatPrices(this.packageInfo.packagePrices);
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
    formatDays(prices) {
      prices.map((price) => {
        if (price.dayOfWeek === "monday") {
          price.dayOfWeek = "L";
        } else if (price.dayOfWeek === "tuesday") {
          price.dayOfWeek = "M";
        } else if (price.dayOfWeek === "wednesday") {
          price.dayOfWeek = "Mi";
        } else if (price.dayOfWeek === "thursday") {
          price.dayOfWeek = "J";
        } else if (price.dayOfWeek === "friday") {
          price.dayOfWeek = "V";
        } else if (price.dayOfWeek === "saturday") {
          price.dayOfWeek = "S";
        } else if (price.dayOfWeek === "sunday") {
          price.dayOfWeek = "D";
        }
      });
    },
    formatSchedules(schedules) {
      return schedules.map((schedule) => {
        return `${schedule.timeStart} - ${schedule.timeEnd}`;
      });
    },
    formatPrices(prices) {
      const groupedByPrice = prices.reduce((acc, curr) => {
        if (!acc[curr.price]) {
          acc[curr.price] = [];
        }
        acc[curr.price].push(curr.dayOfWeek);
        return acc;
      }, {});
      this.prices = Object.keys(groupedByPrice).map((price) => ({
        price: Number(price),
        daysOfWeek: groupedByPrice[price],
      }));
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.btn-background-transparent::before {
  background-color: transparent !important;
}
.imgsPreview .imageHolder {
  width: 120px;
  height: 120px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
